import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M5.874,11.732c0.581-1.356,1.366-2.526,2.349-3.509s2.154-1.767,3.509-2.349C13.087,5.292,14.511,5,16,5
	c1.403,0,2.762,0.265,4.074,0.795c1.313,0.53,2.48,1.277,3.502,2.242l1.862-1.848c0.286-0.296,0.616-0.363,0.988-0.201
	C26.809,6.151,27,6.432,27,6.833v6.417c0,0.249-0.091,0.463-0.272,0.645s-0.396,0.272-0.645,0.272h-6.417
	c-0.4,0-0.683-0.191-0.845-0.573c-0.162-0.373-0.096-0.702,0.2-0.988l1.963-1.977c-0.669-0.63-1.433-1.115-2.292-1.454
	S16.936,8.667,16,8.667c-0.994,0-1.94,0.193-2.843,0.58s-1.684,0.91-2.342,1.568c-0.659,0.659-1.182,1.439-1.568,2.342
	s-0.58,1.85-0.58,2.843c0,0.994,0.193,1.94,0.58,2.843s0.909,1.684,1.568,2.342c0.658,0.659,1.439,1.182,2.342,1.568
	s1.849,0.58,2.843,0.58c1.136,0,2.21-0.249,3.223-0.744c1.012-0.496,1.866-1.198,2.563-2.105c0.066-0.095,0.177-0.152,0.329-0.172
	c0.144,0,0.263,0.043,0.358,0.129l1.962,1.977c0.077,0.077,0.117,0.173,0.122,0.293s-0.026,0.228-0.093,0.322
	c-1.041,1.261-2.302,2.236-3.781,2.93C19.203,26.654,17.642,27,16,27c-1.489,0-2.913-0.292-4.269-0.874
	c-1.354-0.581-2.526-1.366-3.509-2.349s-1.768-2.154-2.349-3.509C5.292,18.913,5,17.489,5,16C5,14.51,5.292,13.087,5.874,11.732z"
		/>
	</g>
);
