import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 5,4 H 28 L 18.535874,15 18.453432,25 13,27 V 15 L 4,4 h 1"
		/>
	</g>
);
