import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M18.692,16c0,0.192-0.073,0.361-0.221,0.509L8.15,26.83c-0.147,0.147-0.317,0.222-0.509,0.222
	c-0.191,0-0.362-0.074-0.509-0.222l-1.107-1.107c-0.147-0.148-0.222-0.316-0.222-0.509s0.074-0.362,0.222-0.51L14.729,16
	L6.024,7.296C5.877,7.148,5.803,6.979,5.803,6.787s0.074-0.362,0.222-0.509L7.132,5.17C7.279,5.022,7.45,4.948,7.641,4.948
	c0.192,0,0.362,0.074,0.509,0.222l10.322,10.32C18.619,15.639,18.692,15.808,18.692,16z M27.197,16c0,0.192-0.073,0.361-0.222,0.509
	L16.654,26.83c-0.146,0.147-0.316,0.222-0.509,0.222c-0.191,0-0.362-0.074-0.51-0.222l-1.107-1.107
	c-0.147-0.148-0.221-0.316-0.221-0.509s0.074-0.362,0.221-0.51L23.233,16l-8.705-8.704c-0.147-0.147-0.221-0.317-0.221-0.509
	s0.074-0.362,0.221-0.509l1.107-1.107c0.147-0.148,0.318-0.222,0.51-0.222c0.192,0,0.362,0.074,0.509,0.222l10.321,10.32
	C27.124,15.639,27.197,15.808,27.197,16z"
		/>
	</g>
);
