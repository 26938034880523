import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 13.357422 2.6210938 L 13.007812 6.0410156 L 11.150391 6.8457031 L 8.3984375 4.6816406 L 4.671875 8.4082031 L 6.8359375 11.064453 L 6.0292969 13.009766 L 2.6210938 13.357422 L 2.6210938 18.642578 L 6.0292969 18.990234 L 6.8339844 20.933594 L 4.6523438 23.587891 L 8.3886719 27.326172 L 11.0625 25.164062 L 13.005859 25.96875 L 13.335938 29.378906 L 18.642578 29.378906 L 18.990234 25.970703 L 20.935547 25.164062 L 23.591797 27.328125 L 27.328125 23.591797 L 25.164062 20.935547 L 25.970703 18.990234 L 29.378906 18.642578 L 29.378906 13.357422 L 25.970703 13.009766 L 25.164062 11.0625 L 27.308594 8.40625 L 23.572266 4.6699219 L 20.933594 6.8339844 L 18.986328 6.0292969 L 18.621094 2.6210938 L 13.357422 2.6210938 z M 15.164062 4.6210938 L 16.824219 4.6210938 L 17.125 7.421875 L 21.275391 9.140625 L 23.439453 7.3652344 L 24.623047 8.5488281 L 22.861328 10.728516 L 24.580078 14.878906 L 27.378906 15.164062 L 27.378906 16.835938 L 24.580078 17.121094 L 22.859375 21.273438 L 24.636719 23.455078 L 23.455078 24.636719 L 21.273438 22.859375 L 17.121094 24.580078 L 16.835938 27.378906 L 15.152344 27.378906 L 14.880859 24.580078 L 10.728516 22.861328 L 8.53125 24.638672 L 7.3476562 23.457031 L 9.140625 21.275391 L 7.4199219 17.121094 L 6.8261719 17.060547 L 4.6210938 16.835938 L 4.6210938 15.164062 L 7.4199219 14.878906 L 9.140625 10.726562 L 7.3632812 8.5449219 L 8.5566406 7.3515625 L 10.849609 9.1542969 L 14.878906 7.4101562 L 15.164062 4.6210938 z M 16 10 C 12.698136 10 10 12.698136 10 16 C 10 19.301864 12.698136 22 16 22 C 19.301864 22 22 19.301864 22 16 C 22 12.698136 19.301864 10 16 10 z M 16 12 C 18.220984 12 20 13.779016 20 16 C 20 18.220984 18.220984 20 16 20 C 13.779016 20 12 18.220984 12 16 C 12 13.779016 13.779016 12 16 12 z "
		/>
	</g>
);
