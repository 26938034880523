import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 10,3.2929688 8.5859375,4.7070312 19.878906,16 8.5859375,27.292969 10,28.707031 22.707031,16 Z"
		/>
	</g>
);
