import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 15.973181,1.0000323 C 10.20627,1.0000323 5.472,5.7343036 5.472,11.501214 c 0,2.142994 0.6571191,4.141902 1.7746652,5.809762 l -0.01943,0.01295 8.7481048,13.68133 8.750266,-13.718032 -0.0065,-0.0043 c 1.106059,-1.662174 1.755279,-3.650816 1.755279,-5.781703 0,-5.7669107 -4.734271,-10.501182 -10.501181,-10.501182 z m 0,5.5269375 c 2.779931,0 4.974244,2.1943126 4.974244,4.9742442 0,1.055909 -0.318581,2.025146 -0.863584,2.823921 l -0.01728,-0.01083 -0.09282,0.144649 c -0.899757,1.223244 -2.339865,2.016507 -4.000552,2.016507 -1.702708,0 -3.180297,-0.828217 -4.073958,-2.104986 l -0.0151,-0.02375 -0.0022,0.0022 c -0.555634,-0.803598 -0.882965,-1.780604 -0.882965,-2.847725 0,-2.779932 2.194312,-4.9742446 4.974244,-4.9742446 z"
		/>
	</g>
);
