import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 7.7070312,6.2929688 6.2929688,7.7070312 24.292969,25.707031 25.707031,24.292969 Z"
		/>
		<path
			className="line"
			d="M 24.292969,6.2929688 6.2929688,24.292969 7.7070312,25.707031 25.707031,7.7070312 Z"
		/>
	</g>
);
