import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 16,2.8828125 1.7636719,10 3.5527344,10.894531 16,17.117188 30.236328,10 Z M 16,5.1171875 25.763672,10 16,14.882812 6.2363281,10 Z"
		/>
		<path
			className="line"
			d="M 9.5527344,12.105469 1.7636719,16 16,23.117188 30.236328,16 22.447266,12.105469 21.552734,13.894531 25.763672,16 16,20.882812 6.2363281,16 10.447266,13.894531 Z"
		/>
		<path
			className="line"
			d="M 9.5527344,18.105469 1.7636719,22 16,29.117188 30.236328,22 22.447266,18.105469 21.552734,19.894531 25.763672,22 16,26.882812 6.2363281,22 10.447266,19.894531 Z"
		/>
	</g>
);
