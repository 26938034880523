import React from 'react';

export default props => (
	<g>
		<path
			className={'line'}
			d="M 1.1520605,1.5273295 7.6519281,0.02736121 C 8.3581638,-0.13513535 9.083149,0.23360686 9.3706432,0.89609287 L 12.370582,7.895945 c 0.262495,0.6124871 0.0875,1.3312219 -0.431241,1.7499631 L 8.151918,12.745843 c 2.249954,4.793648 6.181124,8.781064 11.074775,11.074766 l 3.099937,-3.78742 c 0.424991,-0.518739 1.137477,-0.693736 1.749964,-0.431241 l 6.999858,2.999936 c 0.668736,0.293744 1.037479,1.018729 0.874982,1.724964 l -1.49997,6.499863 c -0.156246,0.674985 -0.756234,1.162475 -1.46247,1.162475 -16.005924,0 -28.9994098,-12.968476 -28.9994098,-28.9993874 0,-0.6999852 0.48124021,-1.3062224 1.1624763,-1.4624691 z"
		/>
	</g>
);
