import React from 'react';

export default props => (
	<g>
		<polygon
			className="line"
			points="23.313,10.729 16.001,23.396 8.688,10.729"
		/>
	</g>
);
