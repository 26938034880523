import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M27.088,17.484c0,0.423-0.343,0.766-0.766,0.766H5.677c-0.423,0-0.766-0.343-0.766-0.766v-2.969
	c0-0.423,0.343-0.766,0.766-0.766h20.646c0.423,0,0.766,0.343,0.766,0.766V17.484z"
		/>
		<path
			className="line"
			d="M17.484,4.912c0.423,0,0.766,0.343,0.766,0.766v20.646c0,0.423-0.343,0.766-0.766,0.766h-2.969
	c-0.423,0-0.766-0.343-0.766-0.766V5.677c0-0.423,0.343-0.766,0.766-0.766H17.484z"
		/>
	</g>
);
