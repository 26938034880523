import React from 'react';

export default props => (
	<g>
		<path className={'line'} d="m15.923 1a15 15 0 1 0 0.15385 0z" />
		<path
			className={'line'}
			d="m16 1v30.001m15-15h-30.001m3.3847-9.2309a20.001 20 0 0 0 23.231 0m0 18.462a20.001 20 0 0 0-23.231 0m10.462-23.847a19.231 19.231 0 0 0 0 29.385m2.3078 0a19.231 19.231 0 0 0 0-29.385"
		/>
	</g>
);
