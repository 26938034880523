import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M20.653,25.472h-1.695V13.243c0-0.423-0.343-0.766-0.766-0.766h-3.207h-1.179h-2.46c-0.423,0-0.766,0.343-0.766,0.766v0.563
	c0,0.424,0.343,0.766,0.766,0.766h1.694v10.899h-1.695c-0.423,0-0.766,0.343-0.766,0.766v0.563c0,0.424,0.343,0.766,0.766,0.766
	h2.456c0.002,0,0.003,0.001,0.005,0.001h4.386c0.002,0,0.003-0.001,0.005-0.001h2.456c0.423,0,0.766-0.342,0.766-0.766v-0.563
	C21.419,25.814,21.076,25.472,20.653,25.472z"
		/>
		<circle className="line" cx="16" cy="7.583" r="3.151" />
	</g>
);
