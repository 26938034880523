import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M26,12.832c0,0.174-0.066,0.327-0.2,0.461l-9.339,9.337c-0.134,0.134-0.287,0.201-0.461,0.201s-0.327-0.067-0.461-0.201
	L6.2,13.293C6.066,13.159,6,13.006,6,12.832s0.066-0.327,0.2-0.461l1.002-1.002c0.134-0.134,0.287-0.2,0.461-0.2
	s0.327,0.066,0.461,0.2L16,19.243l7.876-7.874c0.134-0.134,0.287-0.2,0.461-0.2s0.327,0.066,0.461,0.2l1.002,1.002
	C25.934,12.505,26,12.658,26,12.832z"
		/>
	</g>
);
