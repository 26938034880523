import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="m 9,1.5 c -2.198703,0 -4,1.8012974 -4,4 v 21 c 0,2.198703 1.801297,4 4,4 h 14 c 2.198703,0 4,-1.801297 4,-4 v -15 -1.324219 A 1.0001,1.0001 0 0 0 26.707031,9.46875 L 19.015625,1.7929688 A 1.0001,1.0001 0 0 0 18.310547,1.5 H 17.042969 A 1.0001,1.0001 0 0 0 17,1.5 Z m 0,2 h 6.953125 c -0.0097,0.2214834 -0.02148,0.2963688 -0.02148,0.4960938 V 8.5 c 0,2.198703 1.801297,4 4,4 H 24.5 25 v 14 c 0,1.125297 -0.874703,2 -2,2 H 9 c -1.1252976,0 -2,-0.874703 -2,-2 v -21 c 0,-1.1252972 0.8747024,-2 2,-2 z M 17.953125,3.5546875 24.908203,10.5 H 24.5 19.931641 c -1.125298,0 -2,-0.8747028 -2,-2 V 3.9960938 c 0,-0.1442488 0.01276,-0.2455428 0.02148,-0.4414063 z"
		/>
		<path
			className="line"
			d="m 10.480661,24.00091 q -0.8341334,0 -1.3690667,-0.272 -0.5258667,-0.272 -0.816,-1.024533 -0.2901334,-0.752533 -0.2901334,-2.1488 0,-1.350933 0.2992,-2.0944 0.3082667,-0.752533 0.8432,-1.0336 0.5349334,-0.281067 1.3328001,-0.281067 0.752533,0 1.3056,0.1088 0.562133,0.1088 0.906667,0.253867 v 1.369067 q -0.2992,-0.06347 -0.779734,-0.1088 -0.480533,-0.04533 -1.042666,-0.04533 -0.444267,0 -0.670934,0.136 -0.2266664,0.126934 -0.3354664,0.5168 -0.099733,0.389867 -0.099733,1.1968 0,0.770667 0.0816,1.151467 0.090667,0.3808 0.3263997,0.544 0.235734,0.154133 0.716267,0.154133 1.187733,0 1.804267,-0.0816 v 1.369067 q -0.725334,0.290133 -2.212267,0.290133 z"
		/>
		<path
			className="line"
			d="m 15.638178,24.00091 q -1.160534,0 -2.166934,-0.262933 V 22.34171 q 0.906667,0.0816 2.076267,0.0816 0.4624,0 0.68,-0.09973 0.2176,-0.1088 0.2176,-0.362667 v -0.1088 q 0,-0.1904 -0.136,-0.290133 -0.136,-0.1088 -0.335467,-0.1088 h -0.480533 q -1.078933,0 -1.613867,-0.471467 -0.525866,-0.480533 -0.525866,-1.541333 v -0.2992 q 0,-0.988267 0.589333,-1.486933 0.589333,-0.498667 1.731733,-0.498667 0.997334,0 2.103467,0.262933 v 1.396267 q -1.015467,-0.0816 -1.885867,-0.0816 -0.4352,0 -0.6528,0.1088 -0.2176,0.09973 -0.2176,0.389867 v 0.09067 q 0,0.4624 0.589334,0.4624 h 0.5984 q 0.915733,0 1.405333,0.507734 0.498667,0.507733 0.498667,1.323733 v 0.2992 q 0,0.834133 -0.290134,1.296533 -0.290133,0.453334 -0.825066,0.6256 -0.525867,0.1632 -1.36,0.1632 z"
		/>
		<path
			className="line"
			d="m 18.40252,17.209977 h 1.8224 l 1.2784,4.823467 1.2784,-4.823467 h 1.804266 L 22.59132,23.94651 h -2.194134 z"
		/>
	</g>
);
