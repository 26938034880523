import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="m 9,1.5 c -2.198703,0 -4,1.8012974 -4,4 v 21 c 0,2.198703 1.801297,4 4,4 h 14 c 2.198703,0 4,-1.801297 4,-4 v -15 -1.324219 A 1.0001,1.0001 0 0 0 26.707031,9.46875 L 19.015625,1.7929688 A 1.0001,1.0001 0 0 0 18.310547,1.5 H 17.042969 A 1.0001,1.0001 0 0 0 17,1.5 Z m 0,2 h 6.953125 c -0.0097,0.2214834 -0.02148,0.2963688 -0.02148,0.4960938 V 8.5 c 0,2.198703 1.801297,4 4,4 H 24.5 25 v 14 c 0,1.125297 -0.874703,2 -2,2 H 9 c -1.1252976,0 -2,-0.874703 -2,-2 v -21 c 0,-1.1252972 0.8747024,-2 2,-2 z M 17.953125,3.5546875 24.908203,10.5 H 24.5 19.931641 c -1.125298,0 -2,-0.8747028 -2,-2 V 3.9960938 c 0,-0.1442488 0.01276,-0.2455428 0.02148,-0.4414063 z"
		/>
		<path
			className="line"
			d="M 11.5 11 A 2.4999993 2.4999993 0 0 0 9 13.5 A 2.4999993 2.4999993 0 0 0 10.923828 15.929688 L 11.494141 22.214844 A 2.4999993 2.4999993 0 0 0 10 24.5 A 2.4999993 2.4999993 0 0 0 12.5 27 A 2.4999993 2.4999993 0 0 0 14.955078 24.962891 L 17.753906 24.59375 A 2.4999993 2.4999993 0 0 0 20 26 A 2.4999993 2.4999993 0 0 0 22.5 23.5 A 2.4999993 2.4999993 0 0 0 21.107422 21.261719 L 21.388672 19.417969 A 2.4999993 2.4999993 0 0 0 23.5 16.949219 A 2.4999993 2.4999993 0 0 0 21 14.449219 A 2.4999993 2.4999993 0 0 0 18.988281 15.470703 L 13.992188 13.654297 A 2.4999993 2.4999993 0 0 0 14 13.5 A 2.4999993 2.4999993 0 0 0 11.5 11 z M 13.455078 15.052734 L 18.501953 16.888672 A 2.4999993 2.4999993 0 0 0 18.5 16.949219 A 2.4999993 2.4999993 0 0 0 19.90625 19.195312 L 19.625 21.03125 A 2.4999993 2.4999993 0 0 0 17.533203 23.109375 L 14.777344 23.474609 A 2.4999993 2.4999993 0 0 0 12.986328 22.048828 L 12.421875 15.822266 A 2.4999993 2.4999993 0 0 0 13.455078 15.052734 z "
		/>
	</g>
);
