import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 21.292969,3.2929688 8.5859375,16 21.292969,28.707031 22.707031,27.292969 11.414062,16 22.707031,4.7070312 Z"
		/>
	</g>
);
