import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M16,18.692c-0.192,0-0.361-0.073-0.509-0.221L5.17,8.149C5.022,8.003,4.948,7.833,4.948,7.641
	c0-0.191,0.074-0.362,0.222-0.509l1.107-1.107c0.148-0.147,0.316-0.222,0.509-0.222s0.362,0.074,0.51,0.222L16,14.729l8.704-8.704
	c0.147-0.147,0.317-0.222,0.51-0.222c0.191,0,0.361,0.074,0.509,0.222l1.107,1.107c0.147,0.146,0.222,0.317,0.222,0.509
	c0,0.192-0.074,0.362-0.222,0.509L16.51,18.472C16.361,18.619,16.192,18.692,16,18.692z M16,27.197
	c-0.192,0-0.361-0.073-0.509-0.222L5.17,16.654c-0.147-0.146-0.222-0.316-0.222-0.509c0-0.191,0.074-0.362,0.222-0.51l1.107-1.107
	c0.148-0.146,0.316-0.221,0.509-0.221s0.362,0.074,0.51,0.221L16,23.233l8.704-8.705c0.147-0.146,0.317-0.221,0.51-0.221
	c0.191,0,0.361,0.074,0.509,0.221l1.107,1.107c0.147,0.147,0.222,0.318,0.222,0.51c0,0.192-0.074,0.362-0.222,0.509L16.51,26.976
	C16.361,27.124,16.192,27.197,16,27.197z"
		/>
	</g>
);
