import React from 'react';

export default props => (
	<g>
		<path
			className="polygon"
			d="M11 11 L11 5 L27 5 L27 21 L21 21"
			strokeWidth="3"
		/>
		<rect
			className="polygon"
			x="5"
			y="11"
			width="16"
			height="16"
			strokeWidth="3"
		/>
		<rect className="line" x="5" y="11" width="16" height="4" />
	</g>
);
