import React from 'react';

export default props => (
	<g>
		<path className="line" d="M4 27 l24 0" strokeWidth="2" />
		<path className="line" d="M4 28 l0 -6" strokeWidth="2" />
		<path className="line" d="M28 28 l0 -6" strokeWidth="2" />
		<path className="line" d="M16 5 l0 18" strokeWidth="2" />
		<path className="line" d="M16 5 l7 7" strokeWidth="2" />
		<path className="line" d="M16 5 l-7 7" strokeWidth="2" />
	</g>
);
