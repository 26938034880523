import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="m 19.203116,23.592532 q -1.800558,0 -3.293425,-0.273502 v -1.584035 h 2.529897 q 0.683756,0 0.957259,-0.136751 0.273502,-0.136751 0.273502,-0.490025 v -0.273502 q 0,-0.67236 -0.991446,-0.706548 l -2.051268,-0.09117 V 18.52134 l 1.9601,-0.125355 q 0.512817,-0.02279 0.740736,-0.159543 0.239315,-0.136751 0.239315,-0.444441 V 17.59827 q 0,-0.387462 -0.273503,-0.558401 -0.262106,-0.170939 -0.900278,-0.170939 h -2.336167 v -1.572638 q 1.777766,-0.296295 2.997131,-0.296295 1.185177,0 1.823349,0.535609 0.638172,0.524213 0.638172,1.584035 v 0.603984 q 0,1.219365 -1.002842,1.549847 0.512817,0.159543 0.820507,0.649568 0.30769,0.478629 0.30769,1.185177 v 0.376066 q 0,1.014238 -0.649568,1.561242 -0.638172,0.547005 -1.789161,0.547005 z m -7.79002,-6.7236 L 10,16.9601 v -1.253553 l 1.641014,-0.706548 h 1.743578 v 8.524158 H 11.413096 Z M 9.4765625,3.3515625 A 1.5001498,1.5001498 0 0 0 8,4.8730469 v 2.1484375 a 1.5001498,1.5001498 0 1 0 3,0 V 4.8730469 A 1.5001498,1.5001498 0 0 0 9.4765625,3.3515625 Z M 22.476562,3.4785156 A 1.50015,1.50015 0 0 0 21,5 v 2.1542969 a 1.50015,1.50015 0 1 0 3,0 V 5 A 1.50015,1.50015 0 0 0 22.476562,3.4785156 Z M 5,6.3515625 A 1.0001,1.1771578 0 0 0 4,7.5292969 V 11.822266 A 1.0001,1.1771578 0 0 0 5,13 h 22 a 1.0001,1.1771578 0 0 0 1,-1.177734 V 7.5292969 A 1.0001,1.1771578 0 0 0 27,6.3515625 h -2 v 0.8027344 a 2.50025,2.50025 0 1 1 -5,0 V 6.3515625 h -8 v 0.8164063 a 2.50025,2.50025 0 1 1 -5,0 V 6.3515625 Z M 5,10 a 1.0001,1.0001 0 0 0 -1,1 v 16 a 1.0001,1.0001 0 0 0 1,1 h 22 a 1.0001,1.0001 0 0 0 1,-1 V 11 a 1.0001,1.0001 0 0 0 -1,-1 z m 1,2 H 26 V 26 H 6 Z"
		/>
	</g>
);
