import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="m 9,1.5 c -2.198703,0 -4,1.8012974 -4,4 v 21 c 0,2.198703 1.801297,4 4,4 h 14 c 2.198703,0 4,-1.801297 4,-4 v -15 -1.324219 A 1.0001,1.0001 0 0 0 26.707031,9.46875 L 19.015625,1.7929688 A 1.0001,1.0001 0 0 0 18.310547,1.5 H 17.042969 A 1.0001,1.0001 0 0 0 17,1.5 Z m 0,2 h 6.953125 c -0.0097,0.2214834 -0.02148,0.2963688 -0.02148,0.4960938 V 8.5 c 0,2.198703 1.801297,4 4,4 H 24.5 25 v 14 c 0,1.125297 -0.874703,2 -2,2 H 9 c -1.1252976,0 -2,-0.874703 -2,-2 v -21 c 0,-1.1252972 0.8747024,-2 2,-2 z M 17.953125,3.5546875 24.908203,10.5 H 24.5 19.931641 c -1.125298,0 -2,-0.8747028 -2,-2 V 3.9960938 c 0,-0.1442488 0.01276,-0.2455428 0.02148,-0.4414063 z"
		/>
		<path
			className="line"
			d="m 17.596711,20.583751 3.329625,5.362759 H 17.773505 L 15.858234,22.867344 14.178689,25.94651 H 11.025858 L 14.355483,20.451156 10.981659,15 H 14.13449 L 16.108693,18.197029 17.847169,15 H 21 Z"
		/>
	</g>
);
