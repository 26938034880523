import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="m 11,27 a 1.0001,1.0001 0 1 0 0,2 h 10 a 1.0001,1.0001 0 1 0 0,-2 z m 3,-5 -1,6 h 6 L 18,22 M 4,4 A 1.0001,1.0001 0 0 0 3,5 v 17 a 1.0001,1.0001 0 0 0 1,1 h 24 a 1.0001,1.0001 0 0 0 1,-1 V 5 A 1.0001,1.0001 0 0 0 28,4 Z M 5,6 H 27 V 21 H 5 Z"
		/>
	</g>
);
