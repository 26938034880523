import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 4 4 A 1.0001 1.0001 0 0 0 3 5 L 3 22 A 1.0001 1.0001 0 0 0 4 23 L 13.833984 23 L 13.166016 27 L 11 27 A 1.0001 1.0001 0 1 0 11 29 L 21 29 A 1.0001 1.0001 0 1 0 21 27 L 18.833984 27 L 18.166016 23 L 28 23 A 1.0001 1.0001 0 0 0 29 22 L 29 5 A 1.0001 1.0001 0 0 0 28 4 L 4 4 z M 5 6 L 27 6 L 27 21 L 5 21 L 5 6 z M 18.9375 7.5957031 L 14.888672 15.099609 L 11.439453 10.1875 L 8.1503906 15.470703 L 9.8496094 16.529297 L 11.539062 13.8125 L 15.111328 18.898438 L 19.0625 11.576172 L 22.152344 16.529297 L 23.847656 15.470703 L 18.9375 7.5957031 z"
		/>
	</g>
);
