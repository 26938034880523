import React from 'react';

export default props => (
	<g>
		<path
			className="line"
			d="M 26,16 A 10,10 0 0 1 16,26 10,10 0 0 1 6,16 10,10 0 0 1 16,6 10,10 0 0 1 26,16 Z"
		/>
	</g>
);
